import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  standalone: true
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string; icon: string }) {}
}
