import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';

import { SnackbarStatus } from '../definitions/snackbar-status.enum';
import { SnackbarComponent } from '../shared-components/snackbar/snackbar.component';

/**
 * Shape files Service
 *
 * This service retrieves the region files from the API
 */
@Injectable({
  providedIn: 'root'
})
export class CustomSnackbarService extends MatSnackBar {
  constructor(
    private overlay: Overlay,
    private inj: Injector,
    private breakpointObserver: BreakpointObserver,
    private liveAnnouncer: LiveAnnouncer,
    private snackBar: MatSnackBar,
    private defaultConfig: MatSnackBarConfig,
    private translateService: TranslateService
  ) {
    super(overlay, liveAnnouncer, inj, breakpointObserver, snackBar, defaultConfig);
  }

  private defaultDuration = 3000;

  /**
   * Returns the icon name based on the snackbar status type.
   *
   * @param type The type of the message. It can be 'success', 'error', or 'info'
   * @returns The icon name as a string
   */
  private getIconByType(type: SnackbarStatus): string {
    const icons = {
      [SnackbarStatus.Success]: 'checkmark-circle-outline',
      [SnackbarStatus.Error]: 'alert-circle-outline',
      [SnackbarStatus.Info]: 'information-circle-outline'
    };

    return icons[type] || icons.info;
  }

  /**
   * Presents a snackbar message with a custom icon.
   *
   * @param message The message to display
   * @param type The type of the message. It can be 'success', 'error', or 'info'
   * @returns A reference to the snackbar
   */
  public present(message: string, type: SnackbarStatus = SnackbarStatus.Info): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message, icon: this.getIconByType(type) },
      duration: this.defaultDuration,
      panelClass: `mat-snack-bar-${type}`
    });
  }

  /**
   * Returns the error message if exists, in other case returns 'Something went wrong'
   *
   * @param e Error response from subscribe
   */
  public showError(e: HttpErrorResponse): Observable<never> {
    // If the key doesn't have a translation, the same key is returned
    this.translateService.get(e.error?.errors).subscribe((translation) => {
      if (translation !== e.error?.errors) {
        this.present(translation, SnackbarStatus.Error);
      } else {
        this.present(e.error?.message || 'Something went wrong', SnackbarStatus.Error);
      }
    });

    return throwError(e);
  }
}
